import './index.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import App from './App';
import LoginWrapper from './auth';

import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MsalProvider instance={msalInstance}>
        <LoginWrapper>
            <AuthenticatedTemplate>
            <BrowserRouter>
                <App />
                </BrowserRouter>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div><h1>Error 401</h1></div>
            </UnauthenticatedTemplate>
        </LoginWrapper>
    </MsalProvider>
);