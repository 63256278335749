import { useEffect } from 'react';
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { silentRequest } from './authConfig';
import PropTypes from 'prop-types';

const LoginWrapper = ({ children }) => {
    const { login, error } = useMsalAuthentication(InteractionType.Silent, silentRequest);

    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            console.log(JSON.stringify(error))
            login(InteractionType.Redirect, silentRequest);
        }
    }, [error, login]);

    return (
        <>{ children }</>
    );
};

LoginWrapper.propTypes = {
    children: PropTypes.node,
};

export default LoginWrapper;